body {
  background: #000;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main {
  color: white;
  font-size: 8vw;
  line-height: 2em;
  font-family: "Azeret Mono", monospace;
  opacity: 0.7;
}

.main h2 {
  font-weight: 500;
}

.hour_minute {
  font-size: 21vw;
}

.second {
  font-size: 6vw;
}

.date {
  font-weight: 400;
}
